import { Params } from '@/interfaces/common/global';
import { ProgressDataSubmit, ReportReqType } from '@/interfaces/report';
import { apiServiceWithAuth } from '@/modules/http/apiServiceWithAuth';
import axios from 'axios';

export const getApplicationReportList = async (params?: Params) => {
    return await apiServiceWithAuth.get(`users/report_applications`, { params });
};

export const createApplicationReport = async (payload: { report_application: ReportReqType }) => {
    return await apiServiceWithAuth.post('users/report_applications', payload);
};

export const updateApplicationReport = async (id: string, payload: { report_application: ReportReqType }) => {
    return await apiServiceWithAuth.patch(`/users/report_applications/${id}`, payload);
};

export const getReportApplicationDetail = async (id: string) => {
    return await apiServiceWithAuth.get(`users/report_applications/${id}`);
};

export const createFileApplicationReportCsv = async () => {
    return await apiServiceWithAuth.post(`users/export_csv_report_application`);
};

export const createProgressReportApplication = async (applicationId: string, body: ProgressDataSubmit) => {
    return await apiServiceWithAuth.post(`users/report_applications/${applicationId}/progresses`, body);
};

export const getDetailProgressReportApplication = async (applicationId: string, progressId: string) => {
    return await apiServiceWithAuth.get(`users/report_applications/${applicationId}/progresses/${progressId}`);
};

export const updateProgressReportApplication = async (
    applicationId: string,
    progressId: string,
    body: ProgressDataSubmit
) => {
    return await apiServiceWithAuth.patch(`users/report_applications/${applicationId}/progresses/${progressId}`, body);
};

export const deleteProgressReportApplication = async (applicationId: string, progressId: string) => {
    return await apiServiceWithAuth.delete(`users/report_applications/${applicationId}/progresses/${progressId}`);
};

export const createFileApplicationProgressCsv = async (applicationId: string) => {
    return await apiServiceWithAuth.post(`users/report_applications/${applicationId}/export_csv_report_app_progress`);
};

export const getApplicationReportListByUser = async (userId: string, params?: Params) => {
    return await apiServiceWithAuth.get(`users/${userId}/report_applications`, { params });
};
export const searchLocation = async (lat: number, lng: number) => {
    return await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=${
            process.env.REACT_APP_GOOGLE_MAP_LANGUAGE || 'ja'
        }&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
    );
};
