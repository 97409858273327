import { AttachmentType, CategoriesItem, CategoriesType, ReportRoutes } from '@/interfaces/common/global';
import { MaskitoOptions } from '@maskito/core';

export const TOKEN_NAME = 'authToken';
export const TIME_SEND_EMAIL = 3 * 60; // seconds
export const COMPANY_NAME = '株式会社ナブアシスト';
export const COMPANY_PHONE = '0272531633';
export const DIVISION_PHONE = '0276843111';
export const FORMAT_DATE_TIME = 'YYYY/MM/DD HH:mm';
export const FORMAT_DATE_TIME_SECONDS = 'YYYY/MM/DD HH:mm:ss';
export const FORMAT_DATE = 'YYYY/MM/DD';
export const FORMAT_TIME = 'HH:mm';
export const FORMAT_DATE_REQ = 'YYYY-MM-DD';
export const TYPE_ANSWER = {
    MULTIPLE_CHOICE: 'multiple_choice',
    TEXT: 'text',
};
export const PAGE_SIZE = 10;
export const OPTION_ITEM = 5;
export const MAX_OPTIONS = 10;
export const MIN_DATE = '1800/01/01';
export const MAX_DATE = '9998/12/31';
export const STATUS_APPLICATION_ACCEPTED: {
    [key in 'PENDING' | 'CONFIRMED' | 'RESPONDING' | 'COMPLETED' | 'REJECTED']: string;
} = {
    PENDING: 'pending',
    CONFIRMED: 'confirmed',
    RESPONDING: 'responding',
    COMPLETED: 'completed',
    REJECTED: 'rejected',
};

export const PRIORITY: { [key: string]: string } = {
    URGENT: 'urgent',
    HIGH: 'high',
    MIDDLE: 'middle',
    LOW: 'low',
};

export const ATTENDANCE_STATUS: { [key in 'NOT_YET' | 'ATTEND' | 'ABSENT']: string } = {
    NOT_YET: 'not_yet',
    ATTEND: 'attend',
    ABSENT: 'absent',
};

export const AXIOS_TIMEOUT = 1000 * 60;

export const MAX_SIZE_FILE = 30; //mb
export const MAX_SIZE_FILE_CONVERTED = 2; //mb
export const MAX_SIZE_FILE_CSV = 10; //mb
export const SUPPORT_IMAGE_TYPE = ['png', 'jpeg', 'jpg', 'heic', 'PNG', 'JPEG', 'JPG', 'HEIC'];
export const ACCEPT_IMAGE_TYPE = 'image/png,image/jpeg,image/jpg,image/heic,image/PNG,image/JPEG,image/JPG,image/HEIC';
export const SUPPORT_IMAGE_SIZE = MAX_SIZE_FILE * 1024 * 1024;
export const SUPPORT_CSV_SIZE = MAX_SIZE_FILE_CSV * 1024 * 1024;
export const SUPPORT_CSV = ['csv', 'CSV'];

export const STATUS_ACTION = [
    STATUS_APPLICATION_ACCEPTED.COMPLETED,
    STATUS_APPLICATION_ACCEPTED.PENDING,
    STATUS_APPLICATION_ACCEPTED.REJECTED,
];

export const optionStatus = {
    [STATUS_APPLICATION_ACCEPTED.PENDING]: [
        STATUS_APPLICATION_ACCEPTED.CONFIRMED,
        STATUS_APPLICATION_ACCEPTED.RESPONDING,
        STATUS_APPLICATION_ACCEPTED.COMPLETED,
        STATUS_APPLICATION_ACCEPTED.REJECTED,
    ],
    [STATUS_APPLICATION_ACCEPTED.CONFIRMED]: [
        STATUS_APPLICATION_ACCEPTED.RESPONDING,
        STATUS_APPLICATION_ACCEPTED.COMPLETED,
        STATUS_APPLICATION_ACCEPTED.REJECTED,
    ],
    [STATUS_APPLICATION_ACCEPTED.RESPONDING]: [
        STATUS_APPLICATION_ACCEPTED.RESPONDING,
        STATUS_APPLICATION_ACCEPTED.COMPLETED,
        STATUS_APPLICATION_ACCEPTED.REJECTED,
    ],
};

export const optionEditStatus: any = {
    [STATUS_APPLICATION_ACCEPTED.PENDING]: {
        [STATUS_APPLICATION_ACCEPTED.CONFIRMED]: [
            STATUS_APPLICATION_ACCEPTED.CONFIRMED,
            STATUS_APPLICATION_ACCEPTED.RESPONDING,
            STATUS_APPLICATION_ACCEPTED.COMPLETED,
            STATUS_APPLICATION_ACCEPTED.REJECTED,
        ],
        [STATUS_APPLICATION_ACCEPTED.RESPONDING]: [
            STATUS_APPLICATION_ACCEPTED.CONFIRMED,
            STATUS_APPLICATION_ACCEPTED.RESPONDING,
            STATUS_APPLICATION_ACCEPTED.COMPLETED,
            STATUS_APPLICATION_ACCEPTED.REJECTED,
        ],
    },
    [STATUS_APPLICATION_ACCEPTED.CONFIRMED]: {
        [STATUS_APPLICATION_ACCEPTED.RESPONDING]: [
            STATUS_APPLICATION_ACCEPTED.RESPONDING,
            STATUS_APPLICATION_ACCEPTED.COMPLETED,
            STATUS_APPLICATION_ACCEPTED.REJECTED,
        ],
    },
    [STATUS_APPLICATION_ACCEPTED.RESPONDING]: {
        [STATUS_APPLICATION_ACCEPTED.RESPONDING]: [
            STATUS_APPLICATION_ACCEPTED.RESPONDING,
            STATUS_APPLICATION_ACCEPTED.COMPLETED,
            STATUS_APPLICATION_ACCEPTED.REJECTED,
        ],
    },
};

export const TIME_ZONE_JP = '+09:00';

export const MEDIA_QUERY = {
    IPAD: 1366,
};

export const optionMyNumber = {
    mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
} as MaskitoOptions;

export const optionInsurance = {
    mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/],
} as MaskitoOptions;

export const optionPhoneNumber = {
    mask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
} as MaskitoOptions;

export const optionHomeNumber = {
    mask: [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
} as MaskitoOptions;

export const CATEGORIES: { [key in CategoriesItem]: CategoriesType } = {
    CONTACT: 'Contact',
    COMMUNICATION_APP: 'CommunicationApp',
    REQUEST: 'Request',
};

export const REPORT_ROUTES: { [key in CategoriesItem]: ReportRoutes } = {
    CONTACT: 'contact',
    COMMUNICATION_APP: 'application',
    REQUEST: 'request',
};

export const ATTACHMENT_TYPES: { [key in CategoriesItem]: AttachmentType } = {
    CONTACT: 'contact',
    COMMUNICATION_APP: 'communication_app',
    REQUEST: 'request',
};
export const GOOGLE_MAPS_CONFIG = {
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    region: process.env.REACT_APP_GOOGLE_MAP_REGION,
    language: process.env.REACT_APP_GOOGLE_MAP_LANGUAGE,
};

export const REGISTER_APPLICATION_STEP = {
    SELECT_CATEGORY: 1,
    SELECT_CHILD_CATEGORY: 2,
    ENTER_REASON: 3,
    UPLOAD_IMAGES: 4,
    MARK_MAP: 5,
};

export const ITEM = [
    {
        defaultName: '写真①',
        name: '',
        error: '',
    },
    {
        defaultName: '写真②',
        name: '',
        error: '',
    },
    {
        defaultName: '写真③',
        name: '',
        error: '',
    },
    {
        defaultName: '写真④',
        name: '',
        error: '',
    },
    {
        defaultName: '写真⑤',
        name: '',
        error: '',
    },
];

export const ERROR_RESPONSE = {
    NOT_FOUND: 'RECORD_NOT_FOUND',
    CANNOT_UPDATE_ROLES: 'CANNOT_UPDATE_ROLES',
};
export const UNAUTHORIZE_URL = ['auth/login'];
export const SURVEY_TYPE = {
    GENERAL: 'general',
    SURVEY_61: 'survey_61',
    APPLICATION: 'application',
};

export const EMAIL_INIT = 'admin.zib@gmail.com';
